import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import mariaIslandsEs from '../../assets/data/mariaIslands/es/index.json';
import mariaIslandsEn from '../../assets/data/mariaIslands/en/index.json';
import mariaIslandsFr from '../../assets/data/mariaIslands/fr/index.json';
import mariaIslandsDe from '../../assets/data/mariaIslands/de/index.json';
import mariaIslandsPt from '../../assets/data/mariaIslands/pt/index.json';
import mariaIslandsIt from '../../assets/data/mariaIslands/it/index.json';
import mariaIslandsJp from '../../assets/data/mariaIslands/jp/index.json';
import mariaIslandsCn from '../../assets/data/mariaIslands/cn/index.json';
import MariaIslandsVideo from '../../components/islandsMariaVideo';
// import MariaIslandsSlider from '../../components/mariaIslandsSlider';
import MariaIslandsDescription from '../../components/mariaIslandsDescription';
import MariaIslandsGallery from '../../components/mariaIslandsGallery';
import './styles.css';
import MetaTags from '../../components/metaTags';

const MariaIslands = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(mariaIslandsEs);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(mariaIslandsEs)
    : isLanguage === 'EN' ? setLanguage(mariaIslandsEn)
    : isLanguage === 'FR' ? setLanguage(mariaIslandsFr)
    : isLanguage === 'DE' ? setLanguage(mariaIslandsDe)
    : isLanguage === 'PT' ? setLanguage(mariaIslandsPt)
    : isLanguage === 'IT' ? setLanguage(mariaIslandsIt)
    : isLanguage === 'JP' ? setLanguage(mariaIslandsJp)
    : isLanguage === 'CN' ? setLanguage(mariaIslandsCn)
    : setLanguage(mariaIslandsEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Islas Marías de México - VisitMexico',
      description: 'Descubre las Islas Marías en México, un paraíso natural lleno de biodiversidad y belleza. Vive una aventura única.'
    },
    'EN': {
      title: 'Islas Marías of Mexico - VisitMexico',
      description: 'Discover Islas Marías in Mexico, a natural paradise full of biodiversity and beauty. Experience a unique adventure.'
    },
    'FR': {
      title: 'Islas Marías du Mexique - VisitMexico',
      description: 'Découvrez les Islas Marías au Mexique, un paradis naturel plein de biodiversité et de beauté. Vivez une aventure unique.'
    },
    'DE': {
      title: 'Islas Marías in Mexiko - VisitMexico',
      description: 'Entdecken Sie die Islas Marías in Mexiko, ein natürliches Paradies voller Biodiversität und Schönheit. Erleben Sie ein einzigartiges Abenteuer.'
    },
    'PT': {
      title: 'Islas Marías do México - VisitMexico',
      description: 'Descubra as Islas Marías no México, um paraíso natural cheio de biodiversidade e beleza. Viva uma aventura única.'
    },
    'IT': {
      title: 'Islas Marías del Messico - VisitMexico',
      description: 'Scopri le Islas Marías in Messico, un paradiso naturale ricco di biodiversità e bellezza. Vivi un\'avventura unica.'
    },
    'JP': {
      title: 'メキシコのマリヤス諸島 - VisitMexico',
      description: 'メキシコのマリヤス諸島を発見し、多様な自然と美しさに満ちた楽園を体験しよう。ユニークな冒険を体験してください。'
    },
    'CN': {
      title: '墨西哥的玛丽亚群岛 - VisitMexico',
      description: '探索墨西哥的玛丽亚群岛，这是一个充满生物多样性和美丽的自然天堂。体验独特的冒险。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      {/*<MariaIslandsSlider data={language} />*/}
      <MariaIslandsVideo data={language}/>
      <LazyLoadComponent>
        <MariaIslandsDescription data={language} />
        <MariaIslandsGallery data={language} />
      </LazyLoadComponent>
    </div>
  );
};

export default MariaIslands;
